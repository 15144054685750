import useTeamHook from "./hooks/useTeam";
import demo_logo from "../../../assets/images/demoLogo.png";
import demo_team_logo from "../../../assets/images/demoTeamLogo.png";
import TableCardsListing from "../../../components/TableCardsListing/TableCardsListing";
import CustomBox from "../League/components/CustomBox";
import { EditTeamModal } from "./components/EditTeam";
import { AvailablePlayersModal } from "./components/AvailablePlayers";
import LiveChat from "./components/LiveChat";
import { numberWithCommas } from "../../../utils/helpers";
import clsx from "clsx";
import UpdateTeamBalanceModal from "./components/UpdateTeamBalance";
import { ReactComponent as BackLeft } from "../../../assets/icons/arrow-left.svg";

const Team = () => {
  const {
    team,
    user,
    columns,
    isMyTeam,
    isAdmin,
    league,
    navigate,
    week,
    setWeek,
    weekSlots,
    isFetchingPlayers,
    prjSlots,
    currentWeek,
    uniquePlayers,
    isFetchingPlayersPRJ,
    isFetching,
    refetch,
    orderPlayers,
    currentPosition,
    lastTeamPosition,
    totalFP,
    isFetchingLeague,
    loading,
    setLoading,
    remainingPlayers,
    games,
    currentTeamsNumber,
  } = useTeamHook();

  return (
    <>
      <div className="hidden px-10 pb-6 overflow-x-hidden md:block">
        <div className="w-full flex justify-between">
          <div className="w-[50%] flex flex-row justify-start items-center">
            <img
              src={team?.logo_url || demo_logo}
              alt="CutThroat"
              className="w-[100px] h-[100px] rounded-[50px] mr-6"
            />
            <div>
              <h2 className="font-absoluteEmpire text-[30px] leading-none">
                {team?.name}
              </h2>
              <div className="flex items-center mt-1">
                <img
                  src={user?.profileUrl || demo_team_logo}
                  alt="CutThroat"
                  className="w-[32px] h-[32px] rounded-[50px] mr-1"
                />
                <div>
                  <p className="font-gilroyBold">{user?.name}</p>
                  <p className="text-text-subGrey text-xs font-gilroyMedium">
                    Team Owner
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-[50%] flex flex-row justify-end gap-4">
            {((isMyTeam &&
              league?.league?.draft?.[0]?.status === "completed" &&
              team?.status !== "Eliminated") ||
              isAdmin) && (
              <AvailablePlayersModal
                team={team}
                leagueId={team?.leagueId}
                refetchTeam={refetch}
                isAdmin={isAdmin}
                currentWeek={currentWeek}
                games={games?.games?.filter(
                  (item: any) => item?.schedule?.week === currentWeek
                )}
              />
            )}
            {(isAdmin || isMyTeam) && (
              <EditTeamModal name={team?.name} logo={team?.logo_url} />
            )}
          </div>
        </div>
        <div className="flex flex-row justify-start items-center mt-3">
          <BackLeft />
          <span
            onClick={() => navigate(-1)}
            className="font-gilroyMedium text-base ml-1 cursor-pointer"
          >
            Back
          </span>
        </div>
        <div className="mt-[32px] flex gap-6">
          <CustomBox
            title="League"
            data={league?.league?.name}
            className="capitalize"
            onClick={() => navigate(`/leagues/league/${team?.leagueId}`)}
            isLoading={isFetchingLeague}
          />
          <CustomBox
            title="League Ranking"
            data={
              team?.status === "Eliminated"
                ? `${team?.status} Week ${team?.eliminatedAt}`
                : team?.status === "First Place"
                ? `${team?.status} $${
                    numberWithCommas(league?.league?.prizeFundFirstPlace) || 0
                  }`
                : team?.status === "Second Place"
                ? `${team?.status} $${
                    numberWithCommas(league?.league?.prizeFundSecondPlace) || 0
                  }`
                : `${
                    currentPosition > lastTeamPosition
                      ? `T${lastTeamPosition + 1}`
                      : currentPosition || "-"
                  }/${currentTeamsNumber ?? "-"}`
            }
            className="capitalize"
            isLoading={isFetchingLeague || isFetching}
          />
          <CustomBox
            title="Total Fantasy Points"
            data={`${totalFP || 0} Points`}
            className="capitalize"
            isLoading={isFetching}
          />
          <CustomBox
            title="Remaining Players"
            data={`${remainingPlayers ?? "0"}/${uniquePlayers?.length ?? "0"}`}
            className="capitalize"
            isLoading={loading || isFetching}
          />
          <CustomBox
            title="Free Agent Acquisition Budget"
            data={`$${numberWithCommas(team?.faab_balance?.toFixed(2)) || 0}`}
            className="capitalize"
            showCustomComponent={isAdmin}
            customComponent={
              <UpdateTeamBalanceModal
                teamId={team?.id}
                faab={team?.faab_balance?.toFixed(2)}
              />
            }
            isLoading={isFetching}
          />
        </div>
        <div className="mt-6 flex gap-6">
          <div className={clsx("w-[73%]", !isMyTeam && "!w-[100%]")}>
            <div className="relative px-6 py-4 bg-filterContainer border-[1px] border-modal-modalStroke rounded-t-[12px] flex flex-row justify-between items-center">
              <p className="font-gilroyBold">Roster</p>
              <div className="flex overflow-x-scroll scrollbar ml-4 gap-2">
                {weekSlots?.map((item: number) => (
                  <button
                    key={item}
                    disabled={league?.league?.createdAtWeek > item + 1}
                    className={clsx(
                      "cursor-pointer bg-card-bgGreyLight rounded-[8px] w-auto px-4 py-3 text-nowrap hover:bg-gradient-to-br hover:from-card-bgHover-1 hover:to-card-bgHover-2",
                      (week ? week : currentWeek) === item + 1 &&
                        "bg-gradient-to-br from-card-bgHover-1 to-card-bgHover-2",
                      league?.league?.createdAtWeek > item + 1 && "opacity-50"
                    )}
                    onClick={() => {
                      setLoading(true);
                      setWeek(item + 1);
                    }}
                  >
                    <p
                      className={clsx(
                        "text-[12px] font-gilroyBold whitespace-nowrap",
                        currentWeek === item + 1 && "text-green-1"
                      )}
                    >
                      Week {item + 1}
                    </p>
                  </button>
                ))}
                {prjSlots?.map((item: number) => (
                  <button
                    key={item}
                    className={clsx(
                      "cursor-pointer bg-card-bgGreyLight rounded-[8px] w-auto px-4 py-3 text-nowrap hover:bg-gradient-to-br hover:from-card-bgHover-1 hover:to-card-bgHover-2",
                      (week ? week : currentWeek) === currentWeek + item + 1 &&
                        "bg-gradient-to-br from-card-bgHover-1 to-card-bgHover-2"
                    )}
                    onClick={() => {
                      setLoading(true);
                      setWeek(currentWeek + item + 1);
                    }}
                  >
                    <p className="text-[12px] font-gilroyBold whitespace-nowrap">
                      Week {currentWeek + item + 1} PRJ
                    </p>
                  </button>
                ))}
              </div>
            </div>
            <TableCardsListing
              columns={columns}
              onClickRow={() => {}}
              dataSource={
                uniquePlayers?.length ? orderPlayers(uniquePlayers) : []
              }
              overrideTableContainerClass=""
              loading={
                loading ||
                isFetchingPlayers ||
                isFetchingPlayersPRJ ||
                isFetching ||
                isFetchingLeague
              }
              isTeamPlayers
            />
          </div>
          {isMyTeam ? (
            <LiveChat chatRoomId={league?.league?.chatRoom?.id} />
          ) : null}
        </div>
      </div>

      <div className="block w-[100%] px-5 pb-6 md:hidden">
        <div className="w-full flex justify-between items-center">
          <div className="w-[73%] flex flex-row justify-start items-center gap-2">
            <img
              src={team?.logo_url || demo_logo}
              alt="CutThroat"
              className="w-[60px] h-[60px] rounded-[50px]"
            />
            <div>
              <div className="flex flex-col gap-1 items-start">
                <h2 className="font-absoluteEmpire text-[20px] leading-none">
                  {team?.name}
                </h2>
                <div className="flex items-center mt-1">
                  <img
                    src={demo_team_logo}
                    alt="CutThroat"
                    className="w-[25px] h-[25px] rounded-[50px] mr-1"
                  />
                  <div>
                    <p className="font-gilroyBold">{user?.name}</p>
                    <p className="text-text-subGrey text-xs font-gilroyMedium">
                      Team Owner
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-[27%] flex flex-row justify-end gap-4">
            {(isAdmin || isMyTeam) && (
              <EditTeamModal name={team?.name} logo={team?.logo_url} />
            )}
          </div>
        </div>
        <div className="flex flex-row w-[100%] gap-2 justify-between mt-6">
          <div
            onClick={() => navigate(-1)}
            className="flex flex-row justify-center items-center cursor-pointer rounded-[8px] bg-card-bgGreyDark p-3"
          >
            <BackLeft />
          </div>
          {((isMyTeam &&
            league?.league?.draft?.[0]?.status === "completed" &&
            team?.status !== "Eliminated") ||
            isAdmin) && (
            <AvailablePlayersModal
              team={team}
              leagueId={team?.leagueId}
              refetchTeam={refetch}
              isAdmin={isAdmin}
              currentWeek={currentWeek}
            />
          )}
        </div>
        <div className="mt-[32px] flex flex-col gap-6">
          <div className="flex h-full justify-between items-start gap-4">
            <CustomBox
              title="League Ranking"
              data={`${
                currentPosition > lastTeamPosition
                  ? `T${lastTeamPosition + 1}`
                  : currentPosition || "-"
              }/${currentTeamsNumber ?? "-"}`}
              className="capitalize h-[100%]"
              isLoading={isFetchingLeague || isFetching}
            />
            <CustomBox
              title="Total Fantasy Points"
              data={`${totalFP || 0} Points`}
              className="capitalize h-[100%]"
              isLoading={
                isFetching || isFetchingPlayersPRJ || isFetchingPlayers
              }
            />
          </div>
          <div className="flex h-full justify-between items-start gap-4">
            <CustomBox
              title="Remaining Players"
              data={`${remainingPlayers ?? "0"}/${
                uniquePlayers?.length ?? "0"
              }`}
              className="capitalize h-[100%]"
              isLoading={loading || isFetching}
            />
            <CustomBox
              title="FAAB"
              data={`$${numberWithCommas(team?.faab_balance?.toFixed(2)) || 0}`}
              className="capitalize h-[100%]"
              showCustomComponent={isAdmin}
              customComponent={
                <UpdateTeamBalanceModal
                  teamId={team?.id}
                  faab={team?.faab_balance?.toFixed(2)}
                />
              }
              isLoading={isFetching}
            />
          </div>
        </div>
        <div className="mt-6 relative overflow-x-scroll overflow-x-hidden flex flex-col">
          <div className="px-6 py-4 bg-filterContainer border-[1px] border-modal-modalStroke rounded-t-[12px]">
            <div className="flex justify-between items-center">
              <p className="font-gilroyBold">Players</p>
              <div className="flex overflow-x-scroll ml-4 gap-2 scrollbar-hide">
                {weekSlots?.map((item: number) => (
                  <button
                    key={item}
                    disabled={league?.league?.createdAtWeek > item + 1}
                    className={clsx(
                      "cursor-pointer bg-card-bgGreyLight rounded-[8px] w-auto px-4 py-3 text-nowrap hover:bg-gradient-to-br hover:from-card-bgHover-1 hover:to-card-bgHover-2",
                      (week ? week : currentWeek) === item + 1 &&
                        "bg-gradient-to-br from-card-bgHover-1 to-card-bgHover-2",
                      league?.league?.createdAtWeek > item + 1 && "opacity-50"
                    )}
                    onClick={() => {
                      setLoading(true);
                      setWeek(item + 1);
                    }}
                  >
                    <p
                      className={clsx(
                        "text-[12px] font-gilroyBold whitespace-nowrap",
                        currentWeek === item + 1 && "text-green-1"
                      )}
                    >
                      Week {item + 1}
                    </p>
                  </button>
                ))}
                {prjSlots?.map((item: number) => (
                  <button
                    key={item}
                    className={clsx(
                      "cursor-pointer bg-card-bgGreyLight rounded-[8px] w-auto px-4 py-3 text-nowrap hover:bg-gradient-to-br hover:from-card-bgHover-1 hover:to-card-bgHover-2",
                      (week ? week : currentWeek) === currentWeek + item + 1 &&
                        "bg-gradient-to-br from-card-bgHover-1 to-card-bgHover-2"
                    )}
                    onClick={() => {
                      setLoading(true);
                      setWeek(currentWeek + item + 1);
                    }}
                  >
                    <p className="text-[12px] font-gilroyBold whitespace-nowrap">
                      Week {currentWeek + item + 1} PRJ
                    </p>
                  </button>
                ))}
              </div>
            </div>
          </div>
          <div className="relative overflow-x-scroll overflow-x-hidden">
            <div className="w-[1040px]">
              <TableCardsListing
                columns={columns}
                onClickRow={() => {}}
                dataSource={
                  // week === null || week === currentWeek
                  //   ? team?.player
                  uniquePlayers?.length ? orderPlayers(uniquePlayers) : []
                }
                overrideTableContainerClass=""
                loading={
                  loading ||
                  isFetchingPlayers ||
                  isFetchingPlayersPRJ ||
                  isFetching ||
                  isFetchingLeague
                }
                isTeamPlayers
              />
            </div>
          </div>
        </div>
        {isMyTeam ? (
          <LiveChat
            overrideContainerStyles={"mt-6"}
            chatRoomId={league?.league?.chatRoom?.id}
          />
        ) : null}
      </div>
    </>
  );
};

export default Team;
