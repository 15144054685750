import { TeamCard } from "../../League/components/TeamCard";
import LiveChat from "../../Team/components/LiveChat";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import clsx from "clsx";
import { ReactComponent as Arrow } from "../../../../assets/icons/arrow-left.svg";
import { ReactComponent as ArrowDown } from "../../../../assets/icons/arrow-down.svg";
import { RequestWithdrawModal } from "../Finances/components/RequestWithdraw";
import { DepositModal } from "../Finances/components/Deposit";
import {
  formatDateTimeInTimeZone,
  numberWithCommas,
} from "../../../../utils/helpers";
import { Button } from "../../../../components";
import useHomeHook from "./hooks/useHome";
import { useState } from "react";
import LeagueLogs from "../../League/components/LeagueLogs";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 800 },
    items: 3,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 800, min: 400 },
    items: 2,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 400, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

const Home = () => {
  const {
    userData,
    user,
    activeLeagueId,
    setActiveLeagueId,
    data,
    navigate,
    prevButtonFunc,
    nextButtonFunc,
    balance,
    isJoined,
    regularTeams,
    teamsAtRisk,
    eliminatedTeams,
    CustomButtonGroup,
    inviteLink,
    myTeamId,
    refetchDetails,
    getTeamOwner,
    orangeZoneTeams,
    sortedTeams,
    currentPosition,
    lastTeamPosition,
    registration,
    inProgress,
  } = useHomeHook();

  const [isOpenLatestTransactions, setIsOpenLatestTransactions] =
    useState(false);

  return (
    <>
      <div className="hidden w-[100%] px-10 pb-6 mt-6 overflow-x-hidden md:block">
        {user?.league?.length ? (
          <div className="flex flex-col gap-6 h-auto">
            <div className="flex gap-5">
              <div className="w-[74%] flex gap-1">
                <div className="w-[88%]">
                  <Carousel
                    arrows={false}
                    responsive={responsive}
                    customButtonGroup={<CustomButtonGroup />}
                    itemClass="px-2"
                  >
                    {user?.league?.length ? (
                      user?.league?.map((item: any, index: number) => (
                        <div
                          key={index}
                          onClick={() => setActiveLeagueId(item?.id)}
                          className={clsx(
                            "bg-card-bgGreyDark rounded-[16px] border-[1px] border-modal-modalStroke p-5 h-full flex flex-col justify-center items-center cursor-pointer",
                            item?.id ===
                              (activeLeagueId || user?.league?.[0]?.id) &&
                              "bg-card-bgGreyLight"
                          )}
                        >
                          <div className="flex w-full justify-between items-center">
                            <p className="w-fit text-base font-gilroyBold">
                              {item?.name}
                            </p>
                            <div
                              className={clsx(
                                "text-xs font-gilroyBold px-3 py-2 rounded-[16px] bg-gradient-to-br capitalize text-nowrap w-fit h-[25px]",
                                item?.status === "registration" &&
                                  "from-elements-chipGradient-1 to-elements-chipGradient-2",
                                item?.status === "inProgress" &&
                                  "from-red-bgGradient2-1 to-red-bgGradient2-2",
                                item?.status === "completed" &&
                                  "bg-white text-screenBgGrey",
                                item?.status === "archived" &&
                                  "bg-white text-screenBgGrey"
                              )}
                            >
                              {item?.status || ""}
                            </div>
                          </div>
                          <div className="w-full h-[1px] bg-stroke my-3" />
                          <div className="flex justify-around w-full">
                            <div className="flex flex-col justify-center items-center">
                              <p className="text-xs text-text-grey font-gilroyMedium">
                                Teams
                              </p>
                              <p className="font-gilroyBold">
                                {data?.league?.id === item?.id
                                  ? `${sortedTeams?.length}/${item?.numberOfTeams}`
                                  : item?.numberOfTeams}
                              </p>
                            </div>
                            <div className="flex flex-col justify-center items-center">
                              <p className="text-xs text-text-grey font-gilroyMedium">
                                Entry Fee
                              </p>
                              <p className="font-gilroyBold">
                                ${item?.entryFee}
                              </p>
                            </div>
                            {data?.league?.id === item?.id && (
                              <div className="flex flex-col justify-center items-center">
                                <p className="text-xs text-text-grey font-gilroyMedium">
                                  Rank
                                </p>
                                <p className="font-gilroyBold">
                                  {currentPosition > lastTeamPosition
                                    ? `T${lastTeamPosition + 1}`
                                    : currentPosition || "-"}
                                </p>
                              </div>
                            )}
                            <div className="flex flex-col justify-center items-center">
                              <p className="text-xs text-text-grey font-gilroyMedium">
                                Prize Fund
                              </p>
                              <p className="font-gilroyBold">
                                $
                                {numberWithCommas(
                                  (
                                    Number(item?.prizeFundFirstPlace) +
                                    Number(item?.prizeFundSecondPlace)
                                  )?.toFixed(2)
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <></>
                    )}
                  </Carousel>
                </div>
                <div className="flex w-[12%] gap-2 justify-center items-center">
                  <div
                    onClick={() => prevButtonFunc?.current()}
                    className="bg-modal-inputBg w-[45px] h-[45px] rounded-[24px] flex justify-center items-center cursor-pointer"
                  >
                    <Arrow />
                  </div>
                  <div
                    onClick={() => nextButtonFunc?.current()}
                    className="bg-modal-inputBg w-[45px] h-[45px] rounded-[24px] flex justify-center items-center cursor-pointer"
                  >
                    <Arrow className="rotate-180" />
                  </div>
                </div>
              </div>

              <div className="w-[25%] bg-card-bgGreyDark rounded-[16px] border-[1px] border-modal-modalStroke p-3 flex justify-between md:px-2">
                <div className="w-[50%] flex flex-col  justify-center items-center">
                  <p className="w-fit text-xs text-text-grey">Your Balance</p>
                  <p className="w-fit font-absoluteEmpire text-[28px] md:text-[22px]">
                    ${numberWithCommas(balance?.toFixed(2))}
                  </p>
                </div>
                <div className="w-fit flex flex-col justify-center items-end gap-2">
                  <RequestWithdrawModal
                    balance={Number(user?.balance?.winning_amount)}
                  />
                  <DepositModal refetchBalance={refetchDetails} />
                </div>
              </div>
            </div>
            <div className="flex gap-6 mt-[32px]">
              <div className="w-[75%]">
                <div className="relative px-6 py-4 bg-filterContainer border-t-[1px] border-x-[1px] border-modal-modalStroke rounded-t-[12px] flex flex-row justify-between items-center">
                  <p className="font-gilroyBold">Teams</p>
                  <p
                    className="text-text-red font-gilroyMedium cursor-pointer"
                    onClick={() =>
                      navigate(`/leagues/team/${myTeamId.current}`)
                    }
                  >
                    My Team
                  </p>
                </div>
                <div className="flex flex-wrap justify-start bg-card-bgGreyDark rounded-b-[12px] border-b-[1px] border-x-[1px] border-modal-modalStroke p-6 overflow-hidden gap-4 w-[100%] xl:justify-between lg:flex-row">
                  {!registration && data?.league?.team?.length
                    ? [regularTeams?.length ? regularTeams : orangeZoneTeams]
                        ?.flat()
                        ?.sort(
                          (a: any, b: any) =>
                            Number(b?.latestScore) - Number(a?.latestScore)
                        )
                        .map((item: any) => {
                          myTeamId.current =
                            item?.userId === userData?.id
                              ? item?.id
                              : myTeamId.current;
                          return (
                            <TeamCard
                              key={item?.id}
                              name={item?.name}
                              status={item?.status}
                              position={item?.draftPosition}
                              score={item?.score}
                              latestScore={item?.latestScore}
                              logo={item?.logo_url}
                              id={item?.id}
                              faab={item?.faab_balance}
                              isHome
                              isMe={item?.userId === userData?.id}
                              eliminatedWeek={item?.eliminatedAt}
                              user={getTeamOwner(item?.userId)}
                              prjScore={item?.secondaryScore}
                            />
                          );
                        })
                    : data?.league?.team
                        ?.sort(
                          (a: any, b: any) =>
                            Number(b?.latestScore) - Number(a?.latestScore)
                        )
                        ?.map((item: any) => {
                          myTeamId.current =
                            item?.userId === userData?.id
                              ? item?.id
                              : myTeamId.current;
                          return (
                            <TeamCard
                              key={item?.id}
                              name={item?.name}
                              status={item?.status}
                              position={item?.draftPosition}
                              score={item?.score}
                              logo={item?.logo_url}
                              id={item?.id}
                              faab={item?.faab_balance}
                              isHome
                              isMe={item?.userId === userData?.id}
                              eliminatedWeek={item?.eliminatedAt}
                              user={getTeamOwner(item?.userId)}
                              latestScore={item?.latestScore}
                              prjScore={item?.secondaryScore}
                            />
                          );
                        })}
                  {orangeZoneTeams?.length &&
                  regularTeams?.length &&
                  !registration ? (
                    <div className="flex w-full gap-4 items-center">
                      <div className="h-1 w-full rounded-[4px] bg-gradient-to-br from-orange-4 to-orange-1" />
                      <div className="flex flex-col justify-center items-center">
                        <p className="text-orange-1 font-gilroyBold text-center text-nowrap">
                          Danger zone
                        </p>
                      </div>
                      <div className="h-1 w-full rounded-[4px] bg-gradient-to-br from-orange-1 to-orange-4" />
                    </div>
                  ) : null}
                  {orangeZoneTeams?.length &&
                  regularTeams?.length &&
                  !registration
                    ? orangeZoneTeams
                        ?.sort(
                          (a: any, b: any) =>
                            Number(b?.latestScore) - Number(a?.latestScore)
                        )
                        ?.map((item: any) => {
                          myTeamId.current =
                            item?.userId === userData?.id
                              ? item?.id
                              : myTeamId.current;
                          return (
                            <TeamCard
                              key={item?.id}
                              name={item?.name}
                              status={item?.status}
                              position={item?.draftPosition}
                              score={item?.score}
                              logo={item?.logo_url}
                              id={item?.id}
                              atOrangeZone
                              isMe={item?.userId === userData?.id}
                              eliminatedWeek={item?.eliminatedAt}
                              leagueStatus={data?.league?.status}
                              faab={item?.faab_balance}
                              user={getTeamOwner(item?.userId)}
                              teamScore={item?.teamScore}
                              latestScore={item?.latestScore}
                              prjScore={item?.secondaryScore}
                            />
                          );
                        })
                    : null}
                  {(teamsAtRisk?.length || eliminatedTeams?.length) &&
                  !registration ? (
                    <div className="flex w-full gap-4 items-center">
                      <div className="h-1 w-full rounded-[4px] bg-gradient-to-br from-red-bgGradient-1 to-red-bgGradient-2" />
                      <div className="flex flex-col justify-center items-center">
                        <p className="text-text-red font-gilroyBold whitespace-nowrap">
                          {inProgress ? "Risk zone" : "Eliminated"}
                        </p>
                        {inProgress && (
                          <p className="text-text-red font-gilroyMedium text-xs whitespace-nowrap">
                            (Eliminated —{" "}
                            {formatDateTimeInTimeZone(
                              eliminatedTeams?.[0]?.updatedAt,
                              "ll"
                            )}
                            )
                          </p>
                        )}
                      </div>
                      <div className="h-1 w-full rounded-[4px] bg-gradient-to-br from-red-bgGradient-1 to-red-bgGradient-2" />
                    </div>
                  ) : null}
                  {teamsAtRisk?.length && !registration
                    ? teamsAtRisk
                        ?.sort(
                          (a: any, b: any) =>
                            Number(b?.latestScore) - Number(a?.latestScore)
                        )
                        ?.map((item: any) => {
                          myTeamId.current =
                            item?.userId === userData?.id
                              ? item?.id
                              : myTeamId.current;
                          return (
                            <TeamCard
                              key={item?.id}
                              name={item?.name}
                              status={item?.status}
                              position={item?.draftPosition}
                              score={item?.score}
                              logo={item?.logo_url}
                              id={item?.id}
                              faab={item?.faab_balance}
                              isHome
                              atRisk
                              isMe={item?.userId === userData?.id}
                              eliminatedWeek={item?.eliminatedAt}
                              user={getTeamOwner(item?.userId)}
                              latestScore={item?.latestScore}
                              prjScore={item?.secondaryScore}
                            />
                          );
                        })
                    : null}
                  {eliminatedTeams?.length && !registration
                    ? eliminatedTeams?.map((item: any) => {
                        myTeamId.current =
                          item?.userId === userData?.id
                            ? item?.id
                            : myTeamId.current;
                        return (
                          <TeamCard
                            key={item?.id}
                            name={item?.name}
                            status={item?.status}
                            position={item?.draftPosition}
                            score={item?.score}
                            logo={item?.logo_url}
                            id={item?.id}
                            eliminated
                            faab={item?.faab_balance}
                            isHome
                            isMe={item?.userId === userData?.id}
                            eliminatedWeek={item?.eliminatedAt}
                            user={getTeamOwner(item?.userId)}
                            latestScore={item?.latestScore}
                            prjScore={item?.secondaryScore}
                          />
                        );
                      })
                    : null}
                </div>
              </div>
              <div className="w-[25%] flex flex-col gap-6">
                {isJoined ? (
                  <LiveChat
                    overrideContainerStyles="!w-full"
                    chatRoomId={data?.league?.chatRoom?.id}
                  />
                ) : null}
                <LeagueLogs logs={data?.league?.leagueLog} />
              </div>
            </div>
          </div>
        ) : (
          <div className="w-full">
            <div className="w-full bg-home-banner bg-cover bg-no-repeat rounded-[16px] h-[128px]"></div>
            <div className="w-full bg-card-bgGreyDark rounded-[16px] h-[126px] mt-6 p-6 flex justify-between items-center">
              <div>
                <p className="text-[24px] font-gilroyBold">
                  You are not currently a member of any leagues
                </p>
                <p className="mt-2 text-base text-text-grey font-gilroyMedium">
                  Join our community and start having fun with friends in the
                  heart of fantasy sports!
                </p>
              </div>
              <Button onClick={() => navigate("/leagues")}>
                Choose League
              </Button>
            </div>
          </div>
        )}
      </div>

      <div className="block w-[100%] px-5 pb-6 mt-6 overflow-x-hidden md:hidden">
        <div className="w-full flex flex-col gap-3 h-auto">
          <div className="w-[100%] bg-card-bgGreyDark rounded-[16px] border-[1px] border-modal-modalStroke p-3 flex justify-between">
            <div className="w-[50%] flex flex-col  justify-center items-center">
              <p className="w-fit text-xs text-text-grey">Your Balance</p>
              <p className="w-fit font-absoluteEmpire text-[28px]">
                ${numberWithCommas(balance?.toFixed(2))}
              </p>
            </div>
            <div className="w-fit flex flex-col justify-center items-end gap-2">
              <RequestWithdrawModal
                balance={Number(user?.balance?.winning_amount)}
              />
              <DepositModal refetchBalance={refetchDetails} />
            </div>
          </div>
          {user?.league?.length ? (
            <>
              <div className="w-[100%]">
                <Carousel
                  arrows={false}
                  responsive={responsive}
                  customButtonGroup={<CustomButtonGroup />}
                  itemClass="px-2"
                >
                  {user?.league?.length ? (
                    user?.league?.map((item: any, index: number) => (
                      <div
                        key={index}
                        onClick={() => setActiveLeagueId(item?.id)}
                        className={clsx(
                          "bg-card-bgGreyDark rounded-[16px] border-[1px] border-modal-modalStroke p-5 h-full flex flex-col justify-center items-center cursor-pointer",
                          item?.id ===
                            (activeLeagueId || user?.league?.[0]?.id) &&
                            "bg-card-bgGreyLight"
                        )}
                      >
                        <div className="flex w-full justify-between items-center">
                          <p className="text-base font-gilroyBold">
                            {item?.name}
                          </p>
                          <div
                            className={clsx(
                              "text-xs font-gilroyBold px-4 py-2 rounded-[16px] bg-gradient-to-br capitalize text-nowrap h-[25px]",
                              item?.status === "registration" &&
                                "from-elements-chipGradient-1 to-elements-chipGradient-2",
                              item?.status === "inProgress" &&
                                "from-red-bgGradient2-1 to-red-bgGradient2-2",
                              item?.status === "completed" &&
                                "bg-white text-screenBgGrey",
                              item?.status === "archived" &&
                                "bg-white text-screenBgGrey"
                            )}
                          >
                            {item?.status || ""}
                          </div>
                        </div>
                        <div className="w-full h-[1px] bg-stroke my-3" />
                        <div className="flex justify-around w-full">
                          <div className="flex flex-col justify-center items-center">
                            <p className="text-xs text-text-grey font-gilroyMedium">
                              Teams
                            </p>
                            <p className="font-gilroyBold">
                              {data?.league?.id === item?.id
                                ? `${sortedTeams?.length}/${item?.numberOfTeams}`
                                : item?.numberOfTeams}
                            </p>
                          </div>
                          <div className="flex flex-col justify-center items-center">
                            <p className="text-xs text-text-grey font-gilroyMedium">
                              Entry Fee
                            </p>
                            <p className="font-gilroyBold">${item?.entryFee}</p>
                          </div>
                          {data?.league?.id === item?.id && (
                            <div className="flex flex-col justify-center items-center">
                              <p className="text-xs text-text-grey font-gilroyMedium">
                                Rank
                              </p>
                              <p className="font-gilroyBold">
                                {currentPosition > lastTeamPosition
                                  ? `T${lastTeamPosition + 1}`
                                  : currentPosition || "-"}
                              </p>
                            </div>
                          )}
                          <div className="flex flex-col justify-center items-center">
                            <p className="text-xs text-text-grey font-gilroyMedium">
                              Prize Fund
                            </p>
                            <p className="font-gilroyBold">
                              $
                              {numberWithCommas(
                                (
                                  Number(item?.prizeFundFirstPlace) +
                                  Number(item?.prizeFundSecondPlace)
                                )?.toFixed(2)
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <></>
                  )}
                </Carousel>
              </div>
              <div className="flex gap-3 justify-center items-center">
                <div
                  onClick={() => prevButtonFunc?.current()}
                  className="bg-modal-inputBg w-[48px] h-[48px] rounded-[24px] flex justify-center items-center cursor-pointer"
                >
                  <Arrow />
                </div>
                <div
                  onClick={() => nextButtonFunc?.current()}
                  className="bg-modal-inputBg w-[48px] h-[48px] rounded-[24px] flex justify-center items-center cursor-pointer"
                >
                  <Arrow className="rotate-180" />
                </div>
              </div>
            </>
          ) : (
            <div className="w-full relative">
              <div className="w-full bg-home-banner bg-cover bg-no-repeat rounded-[16px] h-[128px]"></div>
              <div className="text-[30px] absolute top-6 left-0 font-gilroyBold text-center">
                WELCOME TO CUTTHROAT!
              </div>
              <div className="w-full bg-card-bgGreyDark rounded-[16px] mt-6 p-4 flex flex-col justify-between items-center">
                <div>
                  <p className="text-[22px] font-gilroyBold text-center">
                    You are not currently a member of any leagues
                  </p>
                  <p className="mt-2 mb-4 text-base text-text-grey font-gilroyMedium text-center">
                    Join our community and start having fun with friends in the
                    heart of fantasy sports!
                  </p>
                </div>
                <Button className="w-full" onClick={() => navigate("/leagues")}>
                  Choose League
                </Button>
              </div>
            </div>
          )}
          <div className="w-full">
            <div
              onClick={() => setIsOpenLatestTransactions((prev) => !prev)}
              className={`px-6 py-4 bg-filterContainer ${
                isOpenLatestTransactions
                  ? "border-t-[1px] border-x-[1px] rounded-t-[12px]"
                  : "border-[1px] rounded-[12px]"
              } 
                  border-modal-modalStroke flex justify-between items-center`}
            >
              <p className="font-gilroyBold">Transactions</p>
              {isOpenLatestTransactions ? (
                <div className="w-[24px]">
                  <ArrowDown className="rotate-180" />
                </div>
              ) : (
                <div className="w-[24px]">
                  <ArrowDown />
                </div>
              )}
            </div>
            {isOpenLatestTransactions && (
              <LeagueLogs showHeader={false} logs={data?.league?.leagueLog} />
            )}
          </div>
          {user?.league?.length ? (
            <div className="flex flex-col gap-6 mt-[32px]">
              <div className="w-full">
                <div className="relative px-6 py-4 bg-filterContainer border-t-[1px] border-x-[1px] border-modal-modalStroke rounded-t-[12px] flex flex-row justify-between items-center">
                  <p className="font-gilroyBold">Teams</p>
                  <p
                    className="text-text-red font-gilroyMedium cursor-pointer"
                    onClick={() =>
                      navigate(`/leagues/team/${myTeamId.current}`)
                    }
                  >
                    My Team
                  </p>
                </div>
                <div className="flex flex-col justify-center bg-card-bgGreyDark rounded-b-[12px] border-b-[1px] border-x-[1px] border-modal-modalStroke p-6 overflow-hidden gap-4 w-[100%] xl:justify-between">
                  {!registration && data?.league?.team?.length
                    ? [regularTeams?.length ? regularTeams : orangeZoneTeams]
                        ?.flat()
                        ?.sort(
                          (a: any, b: any) =>
                            Number(b?.latestScore) - Number(a?.latestScore)
                        )
                        .map((item: any) => {
                          myTeamId.current =
                            item?.userId === userData?.id
                              ? item?.id
                              : myTeamId.current;
                          return (
                            <TeamCard
                              key={item?.id}
                              name={item?.name}
                              status={item?.status}
                              position={item?.draftPosition}
                              score={item?.score}
                              logo={item?.logo_url}
                              id={item?.id}
                              faab={item?.faab_balance}
                              isHome
                              isMe={item?.userId === userData?.id}
                              eliminatedWeek={item?.eliminatedAt}
                              user={getTeamOwner(item?.userId)}
                              latestScore={item?.latestScore}
                              prjScore={item?.secondaryScore}
                            />
                          );
                        })
                    : data?.league?.team
                        ?.sort(
                          (a: any, b: any) =>
                            Number(b?.latestScore) - Number(a?.latestScore)
                        )
                        ?.map((item: any) => {
                          myTeamId.current =
                            item?.userId === userData?.id
                              ? item?.id
                              : myTeamId.current;
                          return (
                            <TeamCard
                              key={item?.id}
                              name={item?.name}
                              status={item?.status}
                              position={item?.draftPosition}
                              score={item?.score}
                              logo={item?.logo_url}
                              id={item?.id}
                              faab={item?.faab_balance}
                              isHome
                              isMe={item?.userId === userData?.id}
                              eliminatedWeek={item?.eliminatedAt}
                              user={getTeamOwner(item?.userId)}
                              latestScore={item?.latestScore}
                              prjScore={item?.secondaryScore}
                            />
                          );
                        })}
                  {orangeZoneTeams?.length &&
                  regularTeams?.length &&
                  !registration ? (
                    <div className="flex w-full gap-4 items-center">
                      <div className="h-1 w-full rounded-[4px] bg-gradient-to-br from-orange-4 to-orange-1" />
                      <div className="flex flex-col justify-center items-center">
                        <p className="text-orange-1 font-gilroyBold text-center text-nowrap">
                          Danger zone
                        </p>
                      </div>
                      <div className="h-1 w-full rounded-[4px] bg-gradient-to-br from-orange-1 to-orange-4" />
                    </div>
                  ) : null}
                  {orangeZoneTeams?.length &&
                  regularTeams?.length &&
                  !registration
                    ? orangeZoneTeams
                        ?.sort(
                          (a: any, b: any) =>
                            Number(b?.latestScore) - Number(a?.latestScore)
                        )
                        ?.map((item: any) => {
                          myTeamId.current =
                            item?.userId === userData?.id
                              ? item?.id
                              : myTeamId.current;
                          return (
                            <TeamCard
                              key={item?.id}
                              name={item?.name}
                              status={item?.status}
                              position={item?.draftPosition}
                              score={item?.score}
                              logo={item?.logo_url}
                              id={item?.id}
                              atOrangeZone
                              isMe={item?.userId === userData?.id}
                              eliminatedWeek={item?.eliminatedAt}
                              leagueStatus={data?.league?.status}
                              faab={item?.faab_balance}
                              user={getTeamOwner(item?.userId)}
                              teamScore={item?.teamScore}
                              latestScore={item?.latestScore}
                              prjScore={item?.secondaryScore}
                            />
                          );
                        })
                    : null}
                  {(teamsAtRisk?.length || eliminatedTeams?.length) &&
                  !registration ? (
                    <div className="flex w-full gap-4 items-center">
                      <div className="h-1 w-full rounded-[4px] bg-gradient-to-br from-red-bgGradient-1 to-red-bgGradient-2" />
                      <div className="flex flex-col justify-center items-center">
                        <p className="text-text-red font-gilroyBold whitespace-nowrap">
                          {inProgress ? "Risk zone" : "Eliminated"}
                        </p>
                        {inProgress && (
                          <p className="text-text-red font-gilroyMedium text-xs whitespace-nowrap">
                            (Eliminated —{" "}
                            {formatDateTimeInTimeZone(
                              eliminatedTeams?.[0]?.updatedAt,
                              "ll"
                            )}
                            )
                          </p>
                        )}
                      </div>
                      <div className="h-1 w-full rounded-[4px] bg-gradient-to-br from-red-bgGradient-1 to-red-bgGradient-2" />
                    </div>
                  ) : null}
                  {teamsAtRisk?.length && !registration
                    ? teamsAtRisk
                        ?.sort(
                          (a: any, b: any) =>
                            Number(b?.latestScore) - Number(a?.latestScore)
                        )
                        ?.map((item: any) => {
                          myTeamId.current =
                            item?.userId === userData?.id
                              ? item?.id
                              : myTeamId.current;
                          return (
                            <TeamCard
                              key={item?.id}
                              name={item?.name}
                              status={item?.status}
                              position={item?.draftPosition}
                              score={item?.score}
                              logo={item?.logo_url}
                              id={item?.id}
                              faab={item?.faab_balance}
                              isHome
                              atRisk
                              isMe={item?.userId === userData?.id}
                              eliminatedWeek={item?.eliminatedAt}
                              user={getTeamOwner(item?.userId)}
                              latestScore={item?.latestScore}
                              prjScore={item?.secondaryScore}
                            />
                          );
                        })
                    : null}
                  {eliminatedTeams?.length && !registration
                    ? eliminatedTeams?.map((item: any) => {
                        myTeamId.current =
                          item?.userId === userData?.id
                            ? item?.id
                            : myTeamId.current;
                        return (
                          <TeamCard
                            key={item?.id}
                            name={item?.name}
                            status={item?.status}
                            position={item?.draftPosition}
                            score={item?.score}
                            logo={item?.logo_url}
                            id={item?.id}
                            eliminated
                            faab={item?.faab_balance}
                            isHome
                            isMe={item?.userId === userData?.id}
                            eliminatedWeek={item?.eliminatedAt}
                            user={getTeamOwner(item?.userId)}
                            latestScore={item?.latestScore}
                            prjScore={item?.secondaryScore}
                          />
                        );
                      })
                    : null}
                </div>
              </div>
              <div className="w-full flex flex-col gap-6">
                {isJoined ? (
                  <LiveChat
                    overrideContainerStyles="!w-full"
                    chatRoomId={data?.league?.chatRoom?.id}
                  />
                ) : null}
              </div>
            </div>
          ) : null}
          <div className="w-full border-[1px] border-[1px] border-modal-modalStroke bg-card-bgGreyDark rounded-[12px] flex flex-col justify-center items-center p-6 mt-6">
            <p className="text-base text-center font-gilroyBold">
              Make CutThroat more fun with friends!
            </p>
            <p className="text-[12px] text-center text-text-grey font-gilroyMedium">
              Join the game together and compete for victory
            </p>
            <div className="mt-3 flex items-center w-full gap-2 justify-between">
              <div className="w-[60%] bg-modal-inputBg py-2 px-4 rounded-[8px]">
                <p className="text-[12px] text-text-grey font-gilroyMedium max-w-[50%]">
                  {inviteLink?.length > 20
                    ? `${inviteLink?.slice(0, 20)}...`
                    : inviteLink}
                </p>
              </div>
              <Button
                className="rounded-[8px]"
                onClick={() => navigator.clipboard.writeText(inviteLink)}
              >
                Copy invite link
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
