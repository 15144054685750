import { useEffect, useState } from "react";
import clsx from "clsx";
import { Button, Input } from "../../../../components";
import { ReactComponent as Search } from "../../../../assets/icons/search-icon.svg";
import { ReactComponent as Plus } from "../../../../assets/icons/plus-icon.svg";
import { ReactComponent as Arrow } from "../../../../assets/icons/arrowUp-icon.svg";
import { ReactComponent as Check } from "../../../../assets/icons/check.svg";
import TableCardsListing from "../../../../components/TableCardsListing/TableCardsListing";
import { useFetchTeamDetails } from "../../../../queries/teamsQueries/teamsQueries";
import demo_team_logo from "../../../../assets/images/demoTeamLogo.png";
import {
  useAddToQueue,
  useChangeAutoDraftStatus,
  useChangeQueuePriority,
  useFetchTeamQueue,
  useMakePick,
  useRemoveAllQueue,
  useRemoveFromQueue,
} from "../../../../queries/draftQueries/draftQueries";
import useAuthStore from "../../../../stores/auth/auth";
import { toast } from "react-toastify";
import { isValidImg } from "../../../../utils/helpers.ts";
import { useSearchPlayers } from "../../../../queries/playerQueries/playerQueries.tsx";
import { Oval } from "react-loader-spinner";
import PlayerDetailsModal from "../../../../pages/private/Team/components/PlayerDetails.tsx";
import useLoadDraftPlayers from "../hooks/useLoadDraftPlayers.tsx";
import ButtonsMenu from "./ButtonsMenu.tsx";
import { Switch } from "../../../../components/Switch/Switch.tsx";
import { LoadingSkeleton } from "../../../../components/LoadingSkeleton/LoadingSkeleton.tsx";

const RKRender = ({ ...data }) => {
  return (
    <div className="flex justify-between whitespace-nowrap">
      <p className="font-gilroyBold">{data?.ADP ?? "-"}</p>
    </div>
  );
};

const PassATTRender = ({ ...data }) => {
  return (
    <div className="flex justify-center item-center p-2 bg-modal-inputBg rounded-[8px] max-w-[60px]">
      <p className="font-gilroyBold whitespace-nowrap">
        {data?.projectedStats?.passing?.passCompletions ?? "0"}/
        {data?.projectedStats?.passing?.passAttempts ?? "0"}
      </p>
    </div>
  );
};
const PassYardsRender = ({ ...data }) => {
  return (
    <div className="flex justify-center item-center p-2 bg-modal-inputBg rounded-[8px] max-w-[50px]">
      <p className="font-gilroyBold whitespace-nowrap">
        {data?.projectedStats?.passing?.passYards ?? "-"}
      </p>
    </div>
  );
};

const PassTDsRender = ({ ...data }) => {
  return (
    <div className="flex justify-center item-center p-2 bg-modal-inputBg rounded-[8px] max-w-[50px]">
      <p className="font-gilroyBold whitespace-nowrap">
        {data?.projectedStats?.passing?.passTD ?? "-"}
      </p>
    </div>
  );
};

const InttrRender = ({ ...data }) => {
  return (
    <div className="flex justify-center item-center p-2 bg-modal-inputBg rounded-[8px] max-w-[50px]">
      <p className="font-gilroyBold whitespace-nowrap">
        {data?.projectedStats?.passing?.passInt ?? "-"}
      </p>
    </div>
  );
};
const RushYardsRender = ({ ...data }) => {
  return (
    <div className="flex justify-center item-center p-2 bg-modal-inputBg rounded-[8px] max-w-[50px]">
      <p className="font-gilroyBold whitespace-nowrap">
        {data?.projectedStats?.rushing?.rushYards ?? "-"}
      </p>
    </div>
  );
};

const RushTDsRender = ({ ...data }) => {
  return (
    <div className="flex justify-center item-center p-2 bg-modal-inputBg rounded-[8px] max-w-[50px]">
      <p className="font-gilroyBold whitespace-nowrap">
        {data?.projectedStats?.rushing?.rushTD ?? "-"}
      </p>
    </div>
  );
};
const RecYardsRender = ({ ...data }) => {
  return (
    <div className="flex justify-center item-center p-2 bg-modal-inputBg rounded-[8px] max-w-[50px]">
      <p className="font-gilroyBold whitespace-nowrap">
        {data?.projectedStats?.receiving?.recYards ?? "-"}
      </p>
    </div>
  );
};

const RecTDsRender = ({ ...data }) => {
  return (
    <div className="flex justify-center item-center p-2 bg-modal-inputBg rounded-[8px] max-w-[50px]">
      <p className="font-gilroyBold whitespace-nowrap">
        {data?.projectedStats?.receiving?.recTD ?? "-"}
      </p>
    </div>
  );
};
const RecRender = ({ ...data }) => {
  return (
    <div className="flex justify-center item-center p-2 bg-modal-inputBg rounded-[8px] max-w-[50px]">
      <p className="font-gilroyBold whitespace-nowrap">
        {data?.projectedStats?.receiving?.receptions ?? "-"}
      </p>
    </div>
  );
};
const PlayerRender = ({ ...data }) => {
  return (
    <PlayerDetailsModal id={data?.player?.id} leagueId={data?.leagueId}>
      <div className="flex justify-between">
        <div className="flex items-center mt-1">
          <img
            src={isValidImg(data?.player?.officialImageSrc) || demo_team_logo}
            alt="CutThroat"
            className="w-[40px] h-[40px] rounded-[50px] mr-3"
          />
          <div>
            <div className="flex gap-1">
              <p className="font-gilroyBold text-base">
                {data?.player?.firstName}
              </p>
              <p className="font-gilroyBold text-base">
                {data?.player?.lastName}
              </p>
            </div>
            <p className="text-text-grey text-xs font-gilroyMedium">
              {data?.player?.primaryPosition}
            </p>
          </div>
        </div>
      </div>
    </PlayerDetailsModal>
  );
};

const ActionsRender = ({ ...data }) => {
  const { userData } = useAuthStore.getState();
  const { mutateAsync: addToQueue, isPending: isPendingQueue } =
    useAddToQueue();

  return (
    <div className="flex gap-2">
      <Button
        leftIcon={
          isPendingQueue ? (
            <Oval
              visible={isPendingQueue}
              height="20"
              width="20"
              color="#000000"
              secondaryColor="#ffffff"
            />
          ) : (
            <Plus />
          )
        }
        mode="secondary"
        className="rounded-[8px] !h-[40px] w-auto px-3"
        disabled={
          isPendingQueue ||
          data?.draft?.selectedPlayer?.find(
            (item: any) => item?.playerId === data?.player?.id?.toString()
          ) ||
          data?.queue?.find(
            (item: any) => item?.playerId === data?.player?.id?.toString()
          )
        }
        onClick={async () => {
          try {
            await addToQueue({
              leagueId: data?.draftId,
              userId: userData?.id,
              playerId: data?.player?.id,
              teamId: data?.teamId,
            });
          } catch (error: any) {
            toast.error(error?.response?.data?.error);
          }
        }}
      >
        Add to queue
      </Button>
      <Button
        leftIcon={
          <Oval
            visible={data?.loading}
            height="24"
            width="24"
            color="#000000"
            secondaryColor="#ffffff"
          />
        }
        disabled={
          data?.loading ||
          !data?.canMakePick ||
          data?.draft?.status !== "active" ||
          data?.draft?.selectedPlayer?.find(
            (item: any) => item?.playerId === data?.player?.id?.toString()
          )
        }
        onClick={async () => {
          try {
            await data?.onChoose({
              leagueId: data?.leagueId,
              userId: userData?.id,
              playerId: data?.player?.id,
            });
          } catch (error: any) {
            data?.setLoading(false);
            toast.error(error?.response?.data?.error);
          }
        }}
        mode="primary"
        className="rounded-[8px] !h-[40px] w-auto px-4"
      >
        {data?.loading ? "" : "Choose"}
      </Button>
    </div>
  );
};

const PlayersList = ({
  teamId,
  canMakePick,
  draft,
  isFetchingAllPlayers,
}: {
  teamId: string;
  canMakePick: boolean;
  draft: any;
  isFetchingAllPlayers?: boolean;
}) => {
  const { allDraftPlayers } = useAuthStore(["allDraftPlayers"]);
  const { data: { team } = {} } = useFetchTeamDetails(teamId!);
  const [limit, setLimit] = useState(50);
  const { data: queue, refetch } = useFetchTeamQueue(teamId!);
  const { mutateAsync: removePlayerFromQueue } = useRemoveFromQueue();
  const { mutateAsync: removeAllQueue } = useRemoveAllQueue();
  const [activeFilter, setActiveFilter] = useState("All");
  const [allPlayersForFilter, setAllPlayersForFilters] = useState<any>(
    allDraftPlayers?.players?.slice(0, limit)
  );
  const [searchName, setSearchName] = useState("");
  const [firstAttempt, setFirstAttempt] = useState(false);
  const { getNextPlayers } = useLoadDraftPlayers(
    draft?.leagueId || team?.leagueId
  );
  const { mutateAsync: changePlayerPriority } = useChangeQueuePriority();
  const { mutateAsync: onSearchPlayer, isPending: isPendingSearch } =
    useSearchPlayers();
  const { mutateAsync: choosePlayer, isPending: isPendingChoose } =
    useMakePick();
  const [selectedPlayer, setSelectedPlayer] = useState<any>(
    () => draft?.selectedPlayer
  );
  const [loading, setLoading] = useState(false);
  const { mutateAsync: changeAutoDraftSatus } = useChangeAutoDraftStatus();

  useEffect(() => {
    if (isPendingChoose && !loading) {
      setLoading(true);
    } else {
      setTimeout(() => {
        setLoading(false);
      }, 5000);
    }
  }, [isPendingChoose]);

  useEffect(() => {
    if (allDraftPlayers?.players?.length > limit) {
      handlePlayersList(activeFilter);
    } else {
      getNextPlayers();
      handlePlayersList(activeFilter);
    }
  }, [limit]);

  useEffect(() => {
    setSelectedPlayer((prev: any) => {
      if (prev?.length < draft?.selectedPlayer?.length) {
        handlePlayersList(activeFilter);
        refetch();
        return draft?.selectedPlayer;
      } else {
        return prev;
      }
    });
  }, [draft?.selectedPlayer?.length]);

  useEffect(() => {
    if (
      !allPlayersForFilter?.length &&
      !firstAttempt &&
      allDraftPlayers?.players?.length
    ) {
      setAllPlayersForFilters(allDraftPlayers?.players?.slice(0, limit));
      setFirstAttempt(true);
    }
  }, [allDraftPlayers?.players]);

  const handlePlayersList = async (position?: string) => {
    if (searchName) {
      await searchPlayer();
      return;
    } else {
      const selectedPlayersIds = selectedPlayer?.map(
        (item: any) => item?.playerId
      );
      const players = allDraftPlayers?.players
        ?.slice(0, limit)
        ?.filter((item: any) =>
          position !== "All"
            ? position === item?.player?.primaryPosition &&
              !selectedPlayersIds?.includes(item?.player?.id?.toString())
            : !selectedPlayersIds?.includes(item?.player?.id?.toString())
        );
      setAllPlayersForFilters(players);
    }
  };

  const loadMore = () => {
    setLimit(limit + 50);
  };

  const onChangeAutoDraftSatus = async () => {
    try {
      await changeAutoDraftSatus({
        teamId: teamId,
      });
    } catch (error) {
      toast.error("Error changing auto draft status");
    }
  };

  const columsPlayers = [
    {
      title: <p className="text-base font-gilroyMedium text-text-grey">RK</p>,
      dataIndex: "rk",
      render: (_: string, record: any) => {
        return <RKRender {...record} />;
      },
    },
    {
      title: (
        <p className="text-base font-gilroyMedium text-text-grey">
          Selected Players
        </p>
      ),
      dataIndex: "SelectedPlayers",
      render: (_: string, record: any) => {
        return (
          <PlayerRender leagueId={team?.leagueId} {...record} teamId={teamId} />
        );
      },
    },
    {
      title: (
        <p className="text-base font-gilroyMedium text-text-grey">CMP/ATT</p>
      ),
      dataIndex: "passATT",
      render: (_: string, record: any) => {
        return <PassATTRender {...record} />;
      },
    },
    {
      title: <p className="text-base font-gilroyMedium text-text-grey">YDS</p>,
      dataIndex: "passYards",
      render: (_: string, record: any) => {
        return <PassYardsRender {...record} />;
      },
    },
    {
      title: (
        <div className="relative">
          <p className="text-[12px] font-gilroyBold text-text-grey top-[-12px] -translate-x-[100%] absolute">
            Passing
          </p>
          <p className="text-base font-gilroyMedium text-text-grey">TDs</p>
        </div>
      ),
      dataIndex: "passTD",
      render: (_: string, record: any) => {
        return <PassTDsRender {...record} />;
      },
    },
    {
      title: (
        <p className="text-base font-gilroyMedium text-text-grey mr-4">INTs</p>
      ),
      dataIndex: "intrceptions",
      render: (_: string, record: any) => {
        return <InttrRender {...record} />;
      },
    },
    {
      title: <p className="text-base font-gilroyMedium text-text-grey">YDS</p>,
      dataIndex: "rushYards",
      render: (_: string, record: any) => {
        return <RushYardsRender {...record} />;
      },
    },
    {
      title: (
        <div className="relative mr-6">
          <p className="text-[10px] font-gilroyMedium text-text-grey top-[-27px] -translate-x-[62%] absolute whitespace-nowrap">
            Projected Yearly Totals
          </p>
          <p className="text-[12px] font-gilroyBold text-text-grey top-[-12px] -translate-x-[85%] absolute">
            Rushing
          </p>
          <p className="text-base font-gilroyMedium text-text-grey">TDs</p>
        </div>
      ),
      dataIndex: "rushTD",
      render: (_: string, record: any) => {
        return <RushTDsRender {...record} />;
      },
    },

    {
      title: <p className="text-base font-gilroyMedium text-text-grey">Rec</p>,
      dataIndex: "rec",
      render: (_: string, record: any) => {
        return <RecRender {...record} />;
      },
    },
    {
      title: (
        <div className="relative">
          <p className="text-[12px] font-gilroyBold text-text-grey top-[-12px] -translate-x-[20%] absolute">
            Receiving
          </p>
          <p className="text-base font-gilroyMedium text-text-grey">YDS</p>
        </div>
      ),
      dataIndex: "recYards",
      render: (_: string, record: any) => {
        return <RecYardsRender {...record} />;
      },
    },
    {
      title: <p className="text-base font-gilroyMedium text-text-grey">TDs</p>,
      dataIndex: "recTD",
      render: (_: string, record: any) => {
        return <RecTDsRender {...record} />;
      },
    },
    {
      title: (
        <p className="text-base font-gilroyMedium text-text-grey">Actions</p>
      ),
      dataIndex: "actions",
      render: (_: string, record: any) => {
        return (
          <ActionsRender
            leagueId={team?.leagueId}
            draftId={team?.draftId}
            canMakePick={canMakePick}
            teamId={team?.id}
            draft={draft}
            queue={queue}
            refetchQueue={refetch}
            isPendingChoose={isPendingChoose}
            onChoose={choosePlayer}
            loading={loading}
            setLoading={setLoading}
            {...record}
          />
        );
      },
    },
  ];

  const handlePlayersType = (type: string) => {
    switch (type) {
      case "QB":
        setActiveFilter("QB");
        handlePlayersList("QB");
        break;
      case "WR":
        setActiveFilter("WR");
        handlePlayersList("WR");
        break;
      case "RB":
        setActiveFilter("RB");
        handlePlayersList("RB");
        break;
      case "TE":
        setActiveFilter("TE");
        handlePlayersList("TE");
        break;
      case "All":
        setActiveFilter("All");
        handlePlayersList("All");
        break;
      default:
        break;
    }
  };

  const searchPlayer = async () => {
    if (searchName) {
      try {
        const searchedPlayers = await onSearchPlayer({
          name: searchName,
          leagueId: team?.leagueId,
        });
        if (searchedPlayers?.length) {
          setAllPlayersForFilters(searchedPlayers);
        } else {
          const filteredPlayers = allPlayersForFilter?.filter((item: any) => {
            const name = item?.player?.firstName + " " + item?.player?.lastName;
            const result = name
              ?.toLocaleLowerCase()
              ?.includes(searchName?.toLocaleLowerCase());
            return result;
          });
          setAllPlayersForFilters(filteredPlayers);
        }
      } catch (error: any) {
        toast.error(error?.response?.data?.error);
      }
    } else {
      handlePlayersType(activeFilter);
    }
  };

  const Allselected = selectedPlayer?.filter(
    (item: any) => item?.teamId === team?.id
  );

  const getNumberOfPlayers = (position: string) => {
    const players = selectedPlayer?.filter(
      (item: any) => item?.position === position && item?.teamId === team?.id
    );
    return players?.length;
  };

  const minPriority = queue?.length
    ? queue?.reduce((acc: any, item: any) =>
        acc.priority < item.priority ? acc : item
      )
    : null;
  const maxPriority = queue?.length
    ? queue?.reduce((acc: any, item: any) =>
        acc.priority > item.priority ? acc : item
      )
    : null;

  const onRemovePlayerFromQueue = async (playerId: string) => {
    try {
      await removePlayerFromQueue({
        userId: team?.userId,
        playerId: playerId,
        draftId: team?.draftId,
      });
    } catch (error: any) {
      toast.error(error?.response?.data?.error);
    }
  };

  const onChoosePlayer = async (playerId: string) => {
    try {
      await choosePlayer({
        leagueId: team?.leagueId,
        userId: team?.userId,
        playerId: playerId,
      });
    } catch (error: any) {
      setLoading(false);
      toast.error(error?.response?.data?.error);
    }
  };

  return (
    <div className="fixed bottom-0 w-full bg-modal-bgModal flex flex-col md:flex-row h-[45vh] z-[60]">
      <div className=" backdrop-blur-sm py-4 px-6 overflow-x-auto lg:hidden">
        <div className="w-[800px] h-[37vh] bg-modal-grey rounded-[12px] border-[1px] border-card-stroke">
          <div className="w-full static p-4 bg-filterContainer rounded-t-[12px] flex justify-between items-center gap-2">
            <div className="bg-gradient-to-br from-card-bgHover-1 to-card-bgHover-2 rounded-[6px] flex items-center">
              <div
                onClick={() => handlePlayersType("All")}
                className={clsx(
                  "flex flex-col justify-center items-center cursor-pointer py-1 px-2 w-[64px] h-[48px] rounded-[6px]",
                  activeFilter === "All" &&
                    "bg-gradient-to-br from-red-accent-1 to-red-accent-2"
                )}
              >
                <p className="font-gilroyBold">All</p>
                <p className="text-xs">{Allselected?.length}/6</p>
              </div>
              {!(activeFilter === "All" || activeFilter === "QB") && (
                <div className="h-[32px] w-[1px] bg-elements-chipGradient-1" />
              )}
              <div
                onClick={() => handlePlayersType("QB")}
                className={clsx(
                  "flex flex-col justify-center items-center cursor-pointer py-1 px-2 w-[64px] h-[48px] rounded-[6px]",
                  activeFilter === "QB" &&
                    "bg-gradient-to-br from-red-accent-1 to-red-accent-2"
                )}
              >
                <p className="font-gilroyBold">QB</p>
                <p className="text-xs">{getNumberOfPlayers("QB")}/1</p>
              </div>
              {!(activeFilter === "QB" || activeFilter === "RB") && (
                <div className="h-[32px] w-[1px] bg-elements-chipGradient-1" />
              )}
              <div
                onClick={() => handlePlayersType("RB")}
                className={clsx(
                  "flex flex-col justify-center items-center cursor-pointer py-1 px-2 w-[64px] h-[48px] rounded-[6px]",
                  activeFilter === "RB" &&
                    "bg-gradient-to-br from-red-accent-1 to-red-accent-2"
                )}
              >
                <p className="font-gilroyBold">RB</p>
                <p className="text-xs">{getNumberOfPlayers("RB")}/3</p>
              </div>
              {!(activeFilter === "RB" || activeFilter === "WR") && (
                <div className="h-[32px] w-[1px] bg-elements-chipGradient-1" />
              )}
              <div
                onClick={() => handlePlayersType("WR")}
                className={clsx(
                  "flex flex-col justify-center items-center cursor-pointer py-1 px-2 w-[64px] h-[48px] rounded-[6px]",
                  activeFilter === "WR" &&
                    "bg-gradient-to-br from-red-accent-1 to-red-accent-2"
                )}
              >
                <p className="font-gilroyBold">WR</p>
                <p className="text-xs">{getNumberOfPlayers("WR")}/3</p>
              </div>
              {!(activeFilter === "WR" || activeFilter === "TE") && (
                <div className="h-[32px] w-[1px] bg-elements-chipGradient-1" />
              )}
              <div
                onClick={() => handlePlayersType("TE")}
                className={clsx(
                  "flex flex-col justify-center items-center cursor-pointer py-1 px-2 w-[64px] h-[48px] rounded-[6px]",
                  activeFilter === "TE" &&
                    "bg-gradient-to-br from-red-accent-1 to-red-accent-2"
                )}
              >
                <p className="font-gilroyBold">TE</p>
                <p className="text-xs">{getNumberOfPlayers("TE")}/3</p>
              </div>
            </div>
            <div className="w-full">
              <Input
                containerClassName="!h-[48px] rounded-[8px] border-r-0 pr-0 bg-transparent focus-within:bg-transparent"
                inputClassName="placeholder-text-gray text-sm placeholder-font-gilroyMedium"
                placeholder="Find Player..."
                rightIcon={
                  <div className="bg-gradient-to-br from-red-bgGradient-1 to-red-bgGradient-2 h-[48px] w-[48px] flex justify-center items-center rounded-[8px] cursor-pointer">
                    <Search
                      className="w-6 h-6 cursor-pointer"
                      onClick={searchPlayer}
                    />
                  </div>
                }
                onChange={(e) => setSearchName(e?.target?.value)}
                onKeyDown={(e) => {
                  if (e?.key === "Enter") searchPlayer();
                }}
              />
            </div>
          </div>
          <div className="relative overflow-x-scroll overflow-x-hidden scrollbar">
            <div className="min-w-[980px]">
              {isPendingSearch ? (
                <LoadingSkeleton className="w-full p-4" />
              ) : (
                <TableCardsListing
                  columns={columsPlayers}
                  onClickRow={() => {}}
                  dataSource={allPlayersForFilter ?? []}
                  overrideTableContainerClass="!h-[50vh] !overflow-y-scroll scrollbar pt-4"
                  containerClass="border-0"
                  loading={isFetchingAllPlayers}
                  isPlayers
                />
              )}
            </div>
          </div>
          {allDraftPlayers?.players?.length && !searchName ? (
            <div className="w-full flex justify-center">
              <Button
                disabled={isFetchingAllPlayers}
                leftIcon={
                  <Oval
                    visible={isFetchingAllPlayers}
                    height="20"
                    width="20"
                    color="#000000"
                    secondaryColor="#ffffff"
                  />
                }
                className="rounded-[8px] !max-h-[40px]"
                onClick={loadMore}
              >
                Load more
              </Button>
            </div>
          ) : null}
        </div>
      </div>
      <div className="w-[100%] h-[30vh] backdrop-blur-sm pt-4 pb-2 px-6 mb-5 lg:hidden">
        <div className="mt-3 w-[100%] h-full bg-modal-grey rounded-[12px] border-[1px] border-card-stroke overflow-x-hidden">
          <div className="w-full p-4 bg-filterContainer rounded-t-[12px] flex justify-between items-center gap-2">
            <div className="flex items-center gap-2">
              <p className="font-gilroyBold">My Queue</p>
            </div>
            <Button
              mode="secondary"
              className="bg-gradient-to-br from-card-bgHover-1 to-card-bgHover-2 rounded-[8px] whitespace-nowrap !max-w-[130px]"
            >
              Auto Draft
              <Switch
                onChange={onChangeAutoDraftSatus}
                checked={team?.autoPick}
                disabled={draft?.status === "completed"}
              />
            </Button>
          </div>
          {queue?.length ? (
            <div className="py-2 px-6 h-[15vh] overflow-y-scroll scrollbar">
              {queue
                ?.sort((a: any, b: any) => a?.priority - b?.priority)
                ?.map?.((item: any, index: number) => (
                  <div
                    key={index}
                    className={clsx(
                      "flex items-center mt-1 pb-3 w-full",
                      queue?.length > 1 && "border-b-[1px] border-card-stroke"
                    )}
                  >
                    <img
                      src={isValidImg(item?.avatar) || demo_team_logo}
                      alt="CutThroat"
                      className="w-[40px] h-[40px] rounded-[50px] mr-3"
                    />
                    <div className="w-full">
                      <p className="font-gilroyBold text-base">{item?.name}</p>
                      <p className="text-text-grey text-xs font-gilroyMedium">
                        {item?.position}
                      </p>
                    </div>
                    <div className="flex">
                      <div className="flex gap-2 ml-auto">
                        <Button
                          className="rounded-[25px] !max-h-[40px] w-[40px] ml-2"
                          disabled={
                            loading ||
                            !canMakePick ||
                            draft?.status !== "active" ||
                            selectedPlayer?.find(
                              (player: any) =>
                                player?.playerId === item?.playerId
                            )
                          }
                          mode="primary"
                          leftIcon={
                            loading ? (
                              <Oval
                                visible={loading}
                                height="20"
                                width="20"
                                color="#000000"
                                secondaryColor="#ffffff"
                              />
                            ) : (
                              <Check
                                className={clsx(
                                  (loading ||
                                    !canMakePick ||
                                    draft?.status !== "active" ||
                                    selectedPlayer?.find(
                                      (player: any) =>
                                        player?.playerId === item?.playerId
                                    )) &&
                                    "opacity-50"
                                )}
                              />
                            )
                          }
                          onClick={() => onChoosePlayer(item?.playerId)}
                        >
                          {" "}
                        </Button>
                        <Button
                          className={clsx(
                            "!max-h-[40px] !max-w-[40px]",
                            minPriority?.priority === item?.priority &&
                              "bg-card-stroke opacity-50"
                          )}
                          mode="secondary"
                          onClick={async () => {
                            try {
                              minPriority?.priority !== item?.priority &&
                                (await changePlayerPriority({
                                  playerQueueId: item?.id,
                                  direction: "increase",
                                }));
                            } catch (error: any) {
                              console.log(error);
                            }
                          }}
                          leftIcon={<Arrow className="rotate-180" />}
                        >
                          {""}
                        </Button>
                        <div className="flex justify-center items-center h-[40px] w-[45px] rounded-[40px] bg-button-bgSolid">
                          <p className="text-base">{item?.priority}</p>
                        </div>
                        <Button
                          className={clsx(
                            "!max-h-[40px] !max-w-[40px]",
                            maxPriority?.priority === item?.priority &&
                              "bg-card-stroke opacity-50"
                          )}
                          mode="secondary"
                          onClick={async () => {
                            try {
                              maxPriority?.priority !== item?.priority &&
                                (await changePlayerPriority({
                                  playerQueueId: item?.id,
                                  direction: "decrease",
                                }));
                            } catch (error: any) {
                              console.log(error);
                            }
                          }}
                          leftIcon={<Arrow />}
                        >
                          {""}
                        </Button>
                      </div>
                      <ButtonsMenu
                        onRemove={() => onRemovePlayerFromQueue(item?.playerId)}
                      />
                    </div>
                  </div>
                ))}
            </div>
          ) : (
            <div className="flex justify-center items-center h-[70%]">
              <p className="font-gilroyBold text-base">No players in queue</p>
            </div>
          )}
          <div
            className={`mt-4 text-xs text-center ${
              queue?.length ? "text-text-red" : "text-text-lightGrey"
            }`}
          >
            Clear All
          </div>
        </div>
      </div>

      <div className="hidden w-[100%] backdrop-blur-sm py-4 px-6 mb-5 lg:block">
        <div className="flex gap-3 items-start">
          <div className="w-[70%] h-[42vh] bg-modal-grey rounded-[12px] border-[1px] border-card-stroke ">
            <div className="w-full static p-4 bg-filterContainer rounded-t-[12px] flex justify-between items-center gap-2">
              <div className="bg-gradient-to-br from-card-bgHover-1 to-card-bgHover-2 rounded-[6px] flex items-center">
                <div
                  onClick={() => handlePlayersType("All")}
                  className={clsx(
                    "flex flex-col justify-center items-center cursor-pointer py-1 px-2 w-[64px] h-[48px] rounded-[6px]",
                    activeFilter === "All" &&
                      "bg-gradient-to-br from-red-accent-1 to-red-accent-2"
                  )}
                >
                  <p className="font-gilroyBold">All</p>
                  <p className="text-xs">{Allselected?.length}/6</p>
                </div>
                {!(activeFilter === "All" || activeFilter === "QB") && (
                  <div className="h-[32px] w-[1px] bg-elements-chipGradient-1" />
                )}
                <div
                  onClick={() => handlePlayersType("QB")}
                  className={clsx(
                    "flex flex-col justify-center items-center cursor-pointer py-1 px-2 w-[64px] h-[48px] rounded-[6px]",
                    activeFilter === "QB" &&
                      "bg-gradient-to-br from-red-accent-1 to-red-accent-2"
                  )}
                >
                  <p className="font-gilroyBold">QB</p>
                  <p className="text-xs">{getNumberOfPlayers("QB")}/1</p>
                </div>
                {!(activeFilter === "QB" || activeFilter === "RB") && (
                  <div className="h-[32px] w-[1px] bg-elements-chipGradient-1" />
                )}

                <div
                  onClick={() => handlePlayersType("RB")}
                  className={clsx(
                    "flex flex-col justify-center items-center cursor-pointer py-1 px-2 w-[64px] h-[48px] rounded-[6px]",
                    activeFilter === "RB" &&
                      "bg-gradient-to-br from-red-accent-1 to-red-accent-2"
                  )}
                >
                  <p className="font-gilroyBold">RB</p>
                  <p className="text-xs">{getNumberOfPlayers("RB")}/3</p>
                </div>
                {!(activeFilter === "RB" || activeFilter === "WR") && (
                  <div className="h-[32px] w-[1px] bg-elements-chipGradient-1" />
                )}
                <div
                  onClick={() => handlePlayersType("WR")}
                  className={clsx(
                    "flex flex-col justify-center items-center cursor-pointer py-1 px-2 w-[64px] h-[48px] rounded-[6px]",
                    activeFilter === "WR" &&
                      "bg-gradient-to-br from-red-accent-1 to-red-accent-2"
                  )}
                >
                  <p className="font-gilroyBold">WR</p>
                  <p className="text-xs">{getNumberOfPlayers("WR")}/3</p>
                </div>
                {!(activeFilter === "WR" || activeFilter === "TE") && (
                  <div className="h-[32px] w-[1px] bg-elements-chipGradient-1" />
                )}
                <div
                  onClick={() => handlePlayersType("TE")}
                  className={clsx(
                    "flex flex-col justify-center items-center cursor-pointer py-1 px-2 w-[64px] h-[48px] rounded-[6px]",
                    activeFilter === "TE" &&
                      "bg-gradient-to-br from-red-accent-1 to-red-accent-2"
                  )}
                >
                  <p className="font-gilroyBold">TE</p>
                  <p className="text-xs">{getNumberOfPlayers("TE")}/3</p>
                </div>
              </div>
              <div className="w-full">
                <Input
                  containerClassName="!h-[48px] rounded-[8px] border-r-0 pr-0 bg-transparent focus-within:bg-transparent"
                  inputClassName="placeholder-text-gray text-sm placeholder-font-gilroyMedium"
                  placeholder="Find Player..."
                  rightIcon={
                    <div className="bg-gradient-to-br from-red-bgGradient-1 to-red-bgGradient-2 h-[48px] w-[48px] flex justify-center items-center rounded-[8px] cursor-pointer">
                      <Search
                        className="w-6 h-6 cursor-pointer"
                        onClick={searchPlayer}
                      />
                    </div>
                  }
                  onChange={(e) => setSearchName(e?.target?.value)}
                  onKeyDown={(e) => {
                    if (e?.key === "Enter") searchPlayer();
                  }}
                />
              </div>
            </div>
            <div className="md:overflow-x-scroll md:overflow-x-hidden scrollbar">
              <div className="min-w-[980px]">
                {isPendingSearch ? (
                  <LoadingSkeleton className="w-full p-4" />
                ) : (
                  <TableCardsListing
                    columns={columsPlayers}
                    onClickRow={() => {}}
                    dataSource={allPlayersForFilter ?? []}
                    overrideTableContainerClass="md:!h-[25vh] md:!overflow-y-scroll scrollbar pt-4"
                    containerClass="border-0"
                    loading={isFetchingAllPlayers}
                    isPlayers
                  />
                )}
              </div>
            </div>
            {allDraftPlayers?.players?.length && !searchName ? (
              <div className="w-full flex justify-center">
                <Button
                  disabled={isFetchingAllPlayers}
                  leftIcon={
                    <Oval
                      visible={isFetchingAllPlayers}
                      height="20"
                      width="20"
                      color="#000000"
                      secondaryColor="#ffffff"
                    />
                  }
                  className="rounded-[8px] !max-h-[40px]"
                  onClick={loadMore}
                >
                  Load more
                </Button>
              </div>
            ) : null}
          </div>
          <div className="w-[30%] h-[42vh] bg-modal-grey rounded-[12px] border-[1px] border-card-stroke">
            <div className="w-full p-4 bg-filterContainer rounded-t-[12px] flex justify-between items-center gap-2">
              <div>
                <p className="font-gilroyBold">My Queue</p>
              </div>
              <div className="flex gap-2">
                <Button
                  mode="secondary"
                  disabled={!queue?.length}
                  className="bg-gradient-to-br from-card-bgHover-1 to-card-bgHover-2 rounded-[8px] whitespace-nowrap w-[89px]"
                  onClick={async () => {
                    try {
                      await removeAllQueue({
                        draftId: team?.draftId,
                        userId: team?.userId,
                      });
                    } catch (error: any) {
                      toast.error(error?.response?.data?.error);
                    }
                  }}
                >
                  Clear all
                </Button>
                <Button
                  mode="secondary"
                  className="bg-gradient-to-br from-card-bgHover-1 to-card-bgHover-2 rounded-[8px] whitespace-nowrap !max-w-[130px]"
                >
                  Auto Draft
                  <Switch
                    onChange={onChangeAutoDraftSatus}
                    checked={team?.autoPick}
                    disabled={draft?.status === "completed"}
                  />
                </Button>
              </div>
            </div>
            {queue?.length ? (
              <div className="py-2 px-6 h-[32vh] overflow-y-scroll scrollbar">
                {queue
                  ?.sort((a: any, b: any) => a?.priority - b?.priority)
                  ?.map((item: any, index: number) => (
                    <div
                      key={index}
                      className={clsx(
                        "flex items-center mt-1 pb-3 w-full",
                        queue?.length > 1 && "border-b-[1px] border-card-stroke"
                      )}
                    >
                      <img
                        src={isValidImg(item?.avatar) || demo_team_logo}
                        alt="CutThroat"
                        className="w-[40px] h-[40px] rounded-[50px] mr-3"
                      />
                      <div className="w-full">
                        <p className="font-gilroyBold text-base">
                          {item?.name}
                        </p>
                        <p className="text-text-grey text-xs font-gilroyMedium">
                          {item?.position}
                        </p>
                      </div>
                      <div className="flex">
                        <div className="flex gap-[6px] ml-auto">
                          <Button
                            className="rounded-[25px] !max-h-[40px] w-[40px] ml-2"
                            disabled={
                              loading ||
                              !canMakePick ||
                              draft?.status !== "active" ||
                              selectedPlayer?.find(
                                (player: any) =>
                                  player?.playerId === item?.playerId
                              )
                            }
                            mode="primary"
                            leftIcon={
                              loading ? (
                                <Oval
                                  visible={loading}
                                  height="20"
                                  width="20"
                                  color="#000000"
                                  secondaryColor="#ffffff"
                                />
                              ) : (
                                <Check
                                  className={clsx(
                                    (loading ||
                                      !canMakePick ||
                                      draft?.status !== "active" ||
                                      selectedPlayer?.find(
                                        (player: any) =>
                                          player?.playerId === item?.playerId
                                      )) &&
                                      "opacity-50"
                                  )}
                                />
                              )
                            }
                            onClick={() => onChoosePlayer(item?.playerId)}
                          >
                            {" "}
                          </Button>
                          <Button
                            className={clsx(
                              "!max-h-[40px] !max-w-[40px]",
                              minPriority?.priority === item?.priority &&
                                "bg-card-stroke opacity-50"
                            )}
                            mode="secondary"
                            onClick={async () => {
                              try {
                                minPriority?.priority !== item?.priority &&
                                  setTimeout(async () => {
                                    await changePlayerPriority({
                                      playerQueueId: item?.id,
                                      direction: "increase",
                                    });
                                  }, 20);
                              } catch (error: any) {
                                console.log(error);
                              }
                            }}
                            leftIcon={<Arrow className="rotate-180" />}
                          >
                            {""}
                          </Button>
                          <div className="flex justify-center items-center h-[40px] w-[45px] rounded-[40px] bg-button-bgSolid">
                            <p className="text-base">{item?.priority}</p>
                          </div>
                          <Button
                            className={clsx(
                              "!max-h-[40px] !max-w-[40px]",
                              maxPriority?.priority === item?.priority &&
                                "bg-card-stroke opacity-50"
                            )}
                            mode="secondary"
                            onClick={async () => {
                              try {
                                maxPriority?.priority !== item?.priority &&
                                  setTimeout(async () => {
                                    await changePlayerPriority({
                                      playerQueueId: item?.id,
                                      direction: "decrease",
                                    });
                                  }, 20);
                              } catch (error: any) {
                                console.log(error);
                              }
                            }}
                            leftIcon={<Arrow />}
                          >
                            {""}
                          </Button>
                        </div>
                        <ButtonsMenu
                          onRemove={() =>
                            onRemovePlayerFromQueue(item?.playerId)
                          }
                        />
                      </div>
                    </div>
                  ))}
              </div>
            ) : (
              <div className="flex justify-center items-center h-[70%]">
                <p className="font-gilroyBold text-base">No players in queue</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlayersList;
